import React from 'react';
import {Route, Switch} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import Repositories from './Repositories';
import Repository from './Repository';

export default function HomeIndex() {
  return (
    <MainLayout>
      <Switch>
        <Route path="/" exact>
          <Repositories />
        </Route>
        <Route path="/repo/:owner/:name">
          <Repository />
        </Route>
      </Switch>
    </MainLayout>
  );
}
