import React, {useMemo} from 'react';
import {useMediaQuery, ThemeProvider, createMuiTheme} from '@material-ui/core';

export function DynamicThemeProvider({children}: {children: React.ReactNode}) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
