import {useState, useEffect} from 'react';
import {Client, createClient} from 'urql';
import {reaction} from 'mobx';
import {useStores} from '../store';

function buildUrqlClient(token: string) {
  return createClient({
    url: 'https://api.github.com/graphql',
    fetchOptions: () => {
      return {
        headers: {authorization: `Bearer ${token}`},
      };
    },
  });
}

export default function useInitClient() {
  const {globalStore} = useStores();
  const [client, setClient] = useState<null | Client>();

  useEffect(() => {
    return reaction(
      () => globalStore.githubToken,
      (token) => {
        setClient(buildUrqlClient(token || ''));
      },
      {
        fireImmediately: true,
      },
    );
  }, [globalStore.githubToken]);

  return client;
}
