interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
}

export default class GqlArray<T> {
  nodes = null as T[] | null;

  pageInfo?: PageInfo;

  totalCount = 0;

  static fromNodes<T>(rawValue: any, mapper?: (dto: any) => T) {
    const array = new GqlArray<T>();
    if (!rawValue) return array;
    array.totalCount = rawValue.totalCount;
    array.pageInfo = rawValue.pageInfo as PageInfo;
    array.nodes = rawValue.nodes && mapper ? rawValue.nodes.map(mapper) : null;
    return array;
  }
}
