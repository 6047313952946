import React from 'react';
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import {Link} from 'react-router-dom';

export default function ListItemLink({
  primary,
  to,
  icon = null,
}: {
  primary: string;
  icon?: React.ReactNode | null;
  to: string;
}) {
  return (
    <ListItem button component={(props) => <Link to={to} {...props} />}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={primary} />
    </ListItem>
  );
}
