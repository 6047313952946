import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Redirect} from 'react-router-dom';
import {CssBaseline, makeStyles} from '@material-ui/core';
import {useStores} from '../../store';
import TopBar from './TopBar';
import SideMenu from './SideMenu';

interface MainLayoutProps {
  children: React.ReactNode;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
}));

export default observer(function MainLayout({children}: MainLayoutProps) {
  const {globalStore} = useStores();
  const [open, setOpen] = useState(globalStore.sidebarVisibility);
  const classes = useStyles();

  const openDrawer = () => {
    setOpen(true);
    globalStore.setSidebarVisibility(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    globalStore.setSidebarVisibility(false);
  };

  if (!globalStore.githubToken) {
    return <Redirect to="/signin" />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopBar drawerOpen={open} onOpen={openDrawer} />
      <SideMenu open={open} onClose={closeDrawer} />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div>{children}</div>
      </main>
    </div>
  );
});
