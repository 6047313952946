import React from 'react';
import {Switch, Route, BrowserRouter as Router, Redirect} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Provider as UrqlProvider} from 'urql';
import SignIn from './signup/SignIn';
import {useStores, usePersistStore} from './store';
import HomeIndex from './home/HomeIndex';
import useInitClient from './init-hooks/use-init-client';
import {DynamicThemeProvider} from './components/DynamicThemeProvider';

export default observer(function App() {
  const urqlClient = useInitClient();
  const {globalStore} = useStores();
  usePersistStore(globalStore);

  return (
    <DynamicThemeProvider>
      <Router>
        <Switch>
          <Route path="/signin">
            {globalStore.githubToken && <Redirect to="/" />}
            <SignIn />
          </Route>
          <Route path="/">
            {!globalStore.githubToken && <Redirect to="/signin" />}
            {urqlClient && (
              <UrqlProvider value={urqlClient}>
                <HomeIndex />
              </UrqlProvider>
            )}
          </Route>
        </Switch>
      </Router>
    </DynamicThemeProvider>
  );
});
