import React from 'react';
import {TableRow, TableCell, Typography, makeStyles, Link, Button} from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import PullRequestModel from '../../models/PullRequestModel';

const useStyles = makeStyles({
  branch: {
    fontSize: '0.8em',
    fontStyle: 'italic',
  },
});

function isDependabotMergeable(pr: PullRequestModel) {
  return pr.isChecked() && pr.mergeable && pr.author?.login === 'dependabot-preview';
}

export enum PullRequestRowItemAction {
  MERGE,
}

export interface PullRequestRowItemProps {
  onMerge: (pullRequest: PullRequestModel, action: PullRequestRowItemAction) => void;
  pullRequest: PullRequestModel;
}

export default function PullRequestRowItem({onMerge, pullRequest}: PullRequestRowItemProps) {
  const classes = useStyles();

  return (
    <TableRow hover>
      <TableCell component="th" scope="row">
        {pullRequest.isChecked() && <DoneAllIcon />}
        {!pullRequest.isChecked() && <ClearOutlinedIcon />}
      </TableCell>
      <TableCell component="th" scope="row">
        <div>
          <Link href={pullRequest.url} target="_blank" rel="noreferrer noopener">
            {pullRequest.title}
          </Link>
        </div>
        <div>
          <Typography variant="subtitle2" className={classes.branch}>
            {pullRequest.baseRefName}
            &nbsp;&lt;-&nbsp;
            {pullRequest.headRefName}
          </Typography>
        </div>
      </TableCell>
      <TableCell>{pullRequest.author?.login}</TableCell>
      <TableCell align="right">{pullRequest.comments.totalCount}</TableCell>
      <TableCell align="right">{pullRequest.reviews.totalCount}</TableCell>
      {/* <TableCell align="right">{pullRequest.mergeable}</TableCell>
      <TableCell align="right">{pullRequest.isChecked() ? 'yes' : 'no'}</TableCell> */}
      <TableCell align="right">
        {isDependabotMergeable(pullRequest) ? (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              onMerge(pullRequest, PullRequestRowItemAction.MERGE);
            }}>
            Merge dependabot!
          </Button>
        ) : (
          'Cannot be merged'
        )}
      </TableCell>
    </TableRow>
  );
}
