import Commit from './Commit';

export default class CommitPullRequestConnection {
  commit: Commit | null;

  constructor(dto: any) {
    if (dto.commit) {
      this.commit = new Commit(dto.commit);
    } else {
      this.commit = null;
    }
  }
}
