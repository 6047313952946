import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GitHubIcon from '@material-ui/icons/GitHub';
import {Alert} from '@material-ui/lab';
import {useStores} from '../store';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface LoginFormData {
  token: string;
}

function SignIn() {
  const {globalStore} = useStores();
  const classes = useStyles();
  const [error, setError] = useState<null | string>(null);
  const [loginFormData, setLoginFormData] = useState<LoginFormData>({
    token: '',
  });

  const login = async (e: any) => {
    e.preventDefault();
    globalStore.setGitHubToken(loginFormData.token);
  };

  const loginWithGitHub = () => {
    // eslint-disable-next-line new-cap
    const authenticator = new netlify.default({});

    authenticator.authenticate({provider: 'github', scope: 'user repo'}, (err, data) => {
      if (err) {
        setError(`Oops! An error occured with the authentication: ${err}`);
        return;
      }

      setError(null);
      globalStore.setGitHubToken(data.token);
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h2">
          Github Tools
        </Typography>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={login}>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="token"
            label="GitHub token"
            type="text"
            id="token"
            value={loginFormData.token}
            onChange={(e) => {
              e.persist();
              setLoginFormData((v) => ({...v, token: e.target.value}));
            }}
          />
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            Enter
          </Button>
          <hr />
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={loginWithGitHub}
            startIcon={<GitHubIcon />}>
            Login with GitHub
          </Button>
        </form>
      </div>
    </Container>
  );
}

export default SignIn;
