/* eslint-disable max-classes-per-file */
import {createContext, useContext, useEffect} from 'react';
import {observable, action, autorun} from 'mobx';
import 'mobx-react-lite/batchingForReactDom';

class Store {
  storeName?: string;

  constructor(name?: string) {
    this.storeName = name;
    if (!name) return;
    const storedRawValue = localStorage.getItem(name);
    if (storedRawValue) this.hydrate(JSON.parse(storedRawValue));
  }

  serialize() {
    return JSON.stringify(this);
  }

  persist() {
    console.log(`Persist ${this.storeName}`);
    if (this.storeName) localStorage.setItem(this.storeName, this.serialize());
  }

  @action
  hydrate(src: unknown) {
    console.log(`Hydrate ${this.storeName}`);
    Object.assign(this, src);
  }
}

function storeName(name: string) {
  return function classDecorator<T extends {new (...args: any[]): Store}>(constructor: T) {
    return class extends constructor {
      constructor(...args: any[]) {
        super(name);
      }
    };
  };
}

@storeName('globalStore')
class GlobalStore extends Store {
  @observable
  githubToken: null | string = null;

  @observable
  sidebarVisibility = true;

  @action
  setGitHubToken(token: string | null) {
    this.githubToken = token;
  }

  @action
  setSidebarVisibility(visiblity: boolean) {
    this.sidebarVisibility = visiblity;
  }

  @action
  reset() {
    this.setGitHubToken(null);
    this.setSidebarVisibility(true);
  }
}

const storesContext = createContext({
  globalStore: new GlobalStore(),
});

export function useStores() {
  return useContext(storesContext);
}

export function usePersistStore(store: Store) {
  useEffect(() => {
    return autorun(() => {
      store.persist();
    });
  }, [store]);
}
