import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery, useClient} from 'urql';
import {
  LinearProgress,
  Paper,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import gql from '../../lib/gql';
import RepositoryModel from '../../models/RepositoryModel';
import PullRequestRowItem, {PullRequestRowItemAction} from './PullRequestRowItem';
import PullRequestModel from '../../models/PullRequestModel';

const RepoQuery = gql`
  query($owner: String!, $name: String!) {
    repository(owner: $owner, name: $name) {
      id
      name
      url
      owner {
        login
        url
      }
      pullRequests(states: OPEN, first: 100) {
        nodes {
          id
          number
          author {
            login
          }
          title
          url
          comments {
            totalCount
          }
          reviewDecision
          reviews(states: APPROVED) {
            totalCount
          }
          mergeable
          baseRefName
          headRefName
          commits(last: 1) {
            nodes {
              commit {
                status {
                  state
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ApproveMutation = gql`
  mutation ApproveMutation($pullRequestId: String!) {
    addPullRequestReview(input: {pullRequestId: $pullRequestId, event: APPROVE}) {
      clientMutationId
    }
  }
`;

const AddMergeCommentForDependabot = gql`
  mutation AddMergeCommentForDependabot($pullRequestId: String!) {
    addComment(input: {subjectId: $pullRequestId, body: "@dependabot merge"}) {
      clientMutationId
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
  },
  paper: {
    margin: '0 auto',
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
}));

export default function Repository() {
  const classes = useStyles();
  const params = useParams<{repoOwner: string; repoName: string}>();
  const [results] = useQuery({
    query: RepoQuery,
    variables: params,
  });
  const {data, fetching} = results;
  const repo = useMemo(() => (data?.repository ? new RepositoryModel(data.repository) : null), [data]);
  const client = useClient();

  if (fetching) {
    return <LinearProgress />;
  }

  if (!repo) {
    return <div>Repository not found</div>;
  }

  const mergePullRequest = async (pr: PullRequestModel, action: PullRequestRowItemAction) => {
    if (action === PullRequestRowItemAction.MERGE) {
      const approveResult = await client.mutation(ApproveMutation, {pullRequestId: pr.id}).toPromise();
      const commentResult = await client.mutation(AddMergeCommentForDependabot, {pullRequestId: pr.id}).toPromise();
    }
  };

  const {pullRequests} = repo;

  return (
    <div className={classes.container}>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="h5">{repo.name}</Typography>
        <Typography variant="subtitle1">{repo.owner?.login}</Typography>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="h5">Pull requests</Typography>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Name</TableCell>
                <TableCell>Author</TableCell>
                <TableCell>Comments</TableCell>
                <TableCell align="right">Approvals</TableCell>
                {/* <TableCell align="right">Mergeable</TableCell>
                <TableCell align="right">Checked</TableCell> */}
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pullRequests.nodes &&
                pullRequests.nodes.map((pr) => (
                  <PullRequestRowItem key={pr.number} pullRequest={pr} onMerge={mergePullRequest} />
                ))}
              {pullRequests.totalCount === 0 && <div>No item</div>}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
