import clsx from 'clsx';
import React from 'react';
import {AppBar, Toolbar, IconButton, Typography, makeStyles, Menu, MenuItem} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import {useQuery} from 'urql';
import {drawerWidth} from './constants';
import {useStores} from '../../store';
import gql from '../../lib/gql';

interface TopBarProps {
  drawerOpen: boolean;
  onOpen: () => void;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
}));

const UserQuery = gql`
  {
    viewer {
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-empty-function
export default function TopBar({drawerOpen = false, onOpen = () => {}}: TopBarProps) {
  const [result] = useQuery({query: UserQuery});
  const {globalStore} = useStores();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    globalStore.reset();
  };

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => onOpen()}
          className={clsx(classes.menuButton, drawerOpen && classes.menuButtonHidden)}>
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
          Home
        </Typography>
        <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <PersonIcon />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleLogout}>
            Logout&nbsp;
            {result?.data?.viewer?.name && <div>({result?.data?.viewer?.name})</div>}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
