export default class UserModel {
  login: string;

  url: string;

  constructor(dto: any) {
    this.login = dto.login;
    this.url = dto.login;
  }
}
