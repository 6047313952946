import UserModel from './UserModel';
import OrganizationModel from './OrganizationModel';
import PullRequestModel from './PullRequestModel';
import GqlArray from './GqlArray';

export default class RepositoryModel {
  id: string;

  name: string;

  owner: UserModel | null;

  pullRequests: GqlArray<PullRequestModel>;

  constructor(dto: any) {
    this.id = dto.id;
    this.name = dto.name;

    if (dto.owner) {
      if (dto.owner?.__typename === 'Organization') {
        this.owner = new OrganizationModel(dto.owner);
      } else {
        this.owner = new UserModel(dto.owner);
      }
    } else {
      this.owner = null;
    }

    this.pullRequests = GqlArray.fromNodes(dto.pullRequests, (pr: any) => new PullRequestModel(pr));
  }
}
