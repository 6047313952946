import React from 'react';
import {useQuery} from 'urql';
import gql from 'fake-tag';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  LinearProgress,
  Link,
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';

const ReposQuery = gql`
  {
    viewer {
      name
      repositories(first: 100, orderBy: {field: NAME, direction: ASC}) {
        nodes {
          id
          name
          url
          owner {
            login
            url
          }
          pullRequests(states: OPEN, first: 100) {
            nodes {
              author {
                login
              }
              title
              url
            }
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles({
  table: {
    maxWidth: 650,
    margin: '0 auto',
  },
});

export default function Repositories() {
  const classes = useStyles();
  const history = useHistory();
  const [results] = useQuery({query: ReposQuery});
  const {data, fetching} = results;
  const repositories = data?.viewer?.repositories?.nodes || [];

  if (fetching) {
    return <LinearProgress />;
  }

  const openReposDetails = (row: any) => {
    history.push(`/repo/${row.owner.login}/${row.name}`);
  };

  return (
    <TableContainer>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Repository</TableCell>
            <TableCell>Organization</TableCell>
            <TableCell align="right">Pull requests</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {repositories.map((row: any) => (
            <TableRow key={row.name} hover onClick={() => openReposDetails(row)}>
              <TableCell component="th" scope="row">
                <Link href={row.url} target="_blank" rel="noreferrer noopener">
                  {row.name}
                </Link>
              </TableCell>
              <TableCell component="th" scope="row">
                <Link href={row.owner?.url} target="_blank">
                  {row.owner?.login}
                </Link>
              </TableCell>
              <TableCell align="right">
                <Link href={`${row.owner?.url}/pulls`} target="_blank" rel="noreferrer noopener">
                  {row.pullRequests?.nodes?.length}
                </Link>
              </TableCell>
              {/* <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
