import Comment from './Comment';
import Review from './Review';
import UserModel from './UserModel';
import GqlArray from './GqlArray';
import Commit from './Commit';
import CommitPullRequestConnection from './CommitPullRequestConnection';

export default class PullRequestModel {
  id: string;

  title: string;

  baseRefName: string;

  headRefName: string;

  mergeable: string;

  url: string;

  number: number;

  author: UserModel | null;

  comments: GqlArray<Comment>;

  reviews: GqlArray<Review>;

  commits: GqlArray<CommitPullRequestConnection>;

  constructor(dto: any) {
    this.id = dto.id;
    this.title = dto.title;
    this.baseRefName = dto.baseRefName;
    this.headRefName = dto.headRefName;
    this.mergeable = dto.mergeable;
    this.url = dto.url;
    this.number = dto.number;

    if (dto.author) {
      this.author = new UserModel(dto.author);
    } else {
      this.author = null;
    }

    this.comments = GqlArray.fromNodes(dto.comments);
    this.reviews = GqlArray.fromNodes(dto.reviews);
    this.commits = GqlArray.fromNodes(dto.commits, (c: any) => new CommitPullRequestConnection(c));
  }

  isChecked() {
    const connections = this.commits?.nodes;
    if (!connections || !connections.length) return false;
    return connections[0].commit?.status?.state === 'SUCCESS';
  }
}
