import CommitStatus from './CommitStatus';

export default class Commit {
  status: CommitStatus | null;

  constructor(dto: any) {
    if (dto.status) {
      this.status = new CommitStatus(dto.status);
    } else {
      this.status = null;
    }
  }
}
